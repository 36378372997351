import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import {
  SEO,
  TransactionalFirstSection,
  IndustrySubMenu,
  IndustrySubSecondSection,
  IndustrySubThirdSection1,
  HomeCareer,
} from '../components';
import { SEO_TEXT } from '../constants';
import { SanityConsumerType } from '../utils/globalTypes';
import { getClient } from '../utils/sanity.client';
import { businessServicesQuery } from '../utils/querys/businessServices/query';

interface Props {
  data: {
    sanityBusinessServices: SanityConsumerType;
  };
}

const BusinessServices = ({ data: { sanityBusinessServices } }: Props) => {
  const [businessServices, setBusinessServices] = useState<any>();

  useEffect(() => {
    const url = new URL(location.href);
    const getData = async () => {
      const client = getClient({ token: process.env.GATSBY_SANITY_READ_TOKEN || '' });
      const data = await client.fetch(businessServicesQuery);
      setBusinessServices(data);
    };
    if (businessServices === undefined && url.search === '?preview_mode=true') getData();
  }, [businessServices]);
  return (
    <Fragment>
      <SEO
        title={sanityBusinessServices.seo?.title || ''}
        description={sanityBusinessServices.seo?.description || ''}
      />
      <TransactionalFirstSection
        content={businessServices ? businessServices.firstSection : sanityBusinessServices.firstSection}
      />
      <IndustrySubMenu activeIndex={3} />
      <IndustrySubSecondSection
        content={businessServices ? businessServices.secondSection : sanityBusinessServices.secondSection}
      />
      <IndustrySubThirdSection1
        content={businessServices ? businessServices.thirdSection : sanityBusinessServices.thirdSection}
        isLeftContent
      />
    </Fragment>
  );
};

export default BusinessServices;

export const pageQuery = graphql`
  query {
    sanityBusinessServices {
      seo {
        title
        keywords
        description
      }
      firstSection {
        title
        cateogry
      }
      secondSection {
        title
        _rawDescription
        mainImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
      }
      thirdSection {
        sectionItem {
          title
          _rawDescription
        }
        mainImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
        title
      }
    }
  }
`;
